import { createContext, useContext } from 'react';
import React from 'react';
import { useLocalStorage } from './localStorage';
import { useToast } from './toast';
import { Appointment } from 'app/api/AppointmentApi';
import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import { Patient } from '../app/api/PatientApi';

export interface ICopyPasteContext {
  copyAppointment: (appointment: Appointment) => void;
  copyNote: (note: TemporaryNote) => void;
  pasteAppointment: () => { appointment: Appointment; patient: Patient };
  pasteNote: () => TemporaryNote;
  appointmentInClipboard: () => boolean;
  noteInClipboard: () => boolean;
}

const CopyPasteContext = createContext<ICopyPasteContext>({
  copyAppointment: () => {},
  copyNote: () => {},
  pasteAppointment: () => ({} as { appointment: Appointment; patient: Patient }),
  pasteNote: () => ({} as TemporaryNote),
  appointmentInClipboard: () => false,
  noteInClipboard: () => false,
});

export function CopyPasteProvider({ children }) {
  const localStorage = useLocalStorage();
  const toast = useToast();

  const copyAppointment = (appointment: Appointment) => {
    // Lots of keys are empty because they are not needed for the new appointment
    const patient: Patient = {
      globalId: '',
      createdAt: '',
      createdBy: '',
      firstName: appointment.patientFirstName,
      id: appointment.patientId,
      lastName: appointment.patientLastName,
      organizationId: appointment.organizationId,
      phoneDisplay: appointment.patientPhone1 ?? '',
      displayShort: appointment.patientDisplayShort,
      displayLong: appointment.patientDisplayLong,
      displayShortReverse: appointment.patientDisplayShortReverse,
      displayLongReverse: appointment.patientDisplayLongReverse,
      attorneyDescription: '',
      paralegalDescription: '',
    };
    localStorage.setCopyPasteAppointment(appointment, patient);
    toast.toastInfo('Copied', 'Appointment copied');
  };

  const copyNote = (note: TemporaryNote) => {
    localStorage.setCopyPasteTemporaryNote(note);
    toast.toastInfo('Copied', 'Note copied');
  };

  const pasteAppointment = () => {
    const result = localStorage.getCopyPasteAppointment() as { appointment: Appointment; patient: Patient };
    result.appointment.id = undefined as any;
    return result;
  };

  const pasteNote = () => {
    const result = localStorage.getCopyPasteTemporaryNote() as TemporaryNote;
    result.id = undefined as any;
    result.endTime = undefined as any;
    return result;
  };

  return (
    <CopyPasteContext.Provider
      value={{
        copyAppointment,
        copyNote,
        pasteAppointment,
        pasteNote,
        appointmentInClipboard: () => !!localStorage.getCopyPasteAppointment(),
        noteInClipboard: () => !!localStorage.getCopyPasteTemporaryNote(),
      }}
    >
      <>{children}</>
    </CopyPasteContext.Provider>
  );
}

export const useCopyPaste = () => {
  return useContext(CopyPasteContext);
};
