import { faSave, faSpinner, faXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

export type ButtonRowV2Props = {
  onCancel?: () => void;
  onUnrecoverableError: (message: string | JSX.Element) => void;
  onSubmitText?: string;
  onCancelText?: string;
  onSubmitIcon?: IconDefinition;
  onCancelIcon?: IconDefinition;
  onDelete?: () => Promise<void>;
  isDisabled: boolean;
  setIsDisabled: (isDisabled: boolean) => void;
};

export const getClosestLabel: (element: HTMLElement) => HTMLLabelElement | undefined = element => {
  let node = element.parentElement;
  const max = 10;
  let remaining = max;

  while (node && remaining > 0) {
    if (node.getElementsByTagName('label')[0]) {
      return node.getElementsByTagName('label')[0];
    }
    node = node.parentElement;
    remaining -= 1;
  }
};

export function ButtonRowV2({
  onCancel,
  onUnrecoverableError,
  onSubmitText = 'Save',
  onCancelText = 'Cancel',
  onSubmitIcon = faSave,
  onCancelIcon = faXmark,
  onDelete: _onDelete,
  isDisabled,
  setIsDisabled,
}: ButtonRowV2Props) {
  const onDelete = async () => {
    setIsDisabled(true);
    try {
      if (window.confirm('Are you sure? This could cause unexpected behavior if other items reference this.')) {
        _onDelete?.();
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.detail && error.response.data.title) {
        const message = `${error.response.data.title}: ${error.response.data.detail}`;
        onUnrecoverableError(message);
      } else {
        onUnrecoverableError('Unknown error');
      }
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <>
      {_onDelete && (
        <button
          type="button"
          className={classNames(
            'ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
            isDisabled ? 'opacity-50 cursor-not-allowed' : '',
          )}
          onClick={onDelete}
          disabled={isDisabled}
        >
          Delete
        </button>
      )}
      {onCancel && (
        <button
          type="button"
          className={classNames(
            'ml-3 inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            isDisabled ? 'opacity-50 cursor-not-allowed' : '',
          )}
          onClick={onCancel}
          disabled={isDisabled}
        >
          <FontAwesomeIcon className={'-ml-1 mr-3 h-5 w-5'} icon={onCancelIcon} aria-hidden="true" />
          {onCancelText}
        </button>
      )}
      <button
        type="submit"
        className={classNames(
          'ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          isDisabled ? 'opacity-50 cursor-not-allowed' : '',
        )}
        disabled={isDisabled}
      >
        <FontAwesomeIcon
          className={classNames('-ml-1 mr-3 h-5 w-5', isDisabled ? 'animate-spin' : '')}
          icon={isDisabled ? faSpinner : onSubmitIcon}
          aria-hidden="true"
        />
        {onSubmitText}
      </button>
    </>
  );
}
