import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { TemporaryNote, useGetTemporaryNotesBySchedule, useSetTemporaryNotes } from 'app/api/TemporaryNoteApi';
import { PushEvent, useWebsockets } from 'utils/websocket';
import { useAuth } from 'utils/auth';

export interface ITemporaryNotesContext {
  temporaryNotes: TemporaryNote[] | null;
  isLoading: boolean;
  isError: boolean;
}

const TemporaryNotesContext = createContext<ITemporaryNotesContext>({
  temporaryNotes: null,
  isLoading: false,
  isError: false,
});

const sortByTime = (a: TemporaryNote, b: TemporaryNote) => {
  // sort baed on a string field called "time" formatted like HH:mm
  const aTime = a.startTime.split(':');
  const bTime = b.startTime.split(':');

  if (aTime[0] > bTime[0]) {
    return 1;
  }
  if (aTime[0] < bTime[0]) {
    return -1;
  }
  if (aTime[1] > bTime[1]) {
    return 1;
  }
  if (aTime[1] < bTime[1]) {
    return -1;
  }
  if (a.header ?? '' > (b.header ?? '')) {
    return 1;
  }
  if (a.header ?? '' < (b.header ?? '')) {
    return -1;
  }
  if (a.body > b.body) {
    return 1;
  }
  if (a.body < b.body) {
    return -1;
  }
  return 0;
};

const processTemporaryNotes: (
  scheduleDefinitionId: string | null | undefined,
  date: string | null | undefined,
  initialSet: TemporaryNote[] | null | undefined,
  newEvents: PushEvent<TemporaryNote>[],
) => TemporaryNote[] = (scheduleDefinitionId, date, initialSet, newEvents) => {
  if (!scheduleDefinitionId || !date) {
    return [];
  }

  let accumulator = [...(initialSet ?? [])];

  for (const event of newEvents) {
    // Only process events for the current schedule and date
    if (event.message.targetId !== scheduleDefinitionId || event.message.noteDate !== date) {
      continue;
    }

    if (event.subject.includes('TemporaryNoteCreated')) {
      const found = accumulator.find(t => t.id === event.message.id);
      if (!found) {
        accumulator.push(event.message);
      }
    }

    if (event.subject.includes('TemporaryNoteUpdated')) {
      const found = accumulator.find(t => t.id === event.message.id);
      if (found) {
        const indexOfFound = accumulator.findIndex(t => t.id === event.message.id);
        accumulator[indexOfFound] = event.message;
      }
    }

    if (event.subject.includes('TemporaryNoteDeleted')) {
      const indexOfDeletedTask = accumulator.findIndex(t => t.id === event.message.id);
      if (indexOfDeletedTask > -1) {
        accumulator.splice(indexOfDeletedTask, 1);
      }
    }
  }

  return accumulator;
};

export function TemporaryNotesProvider({
  scheduleDefinitionId,
  date,
  children,
}: {
  scheduleDefinitionId: string | null | undefined;
  date: string | null | undefined;
  children: JSX.Element;
}) {
  const { user } = useAuth();

  const [fetchData, setFetchData] = useState(false);
  const { temporaryNoteLedger, temporaryNoteLedgerOffset, setTemporaryNoteLedgerOffset } = useWebsockets();

  const {
    data: temporaryNotes,
    isLoading: temporaryNotesIsLoading,
    isError: temporaryNotesIsError,
  } = useGetTemporaryNotesBySchedule(fetchData, scheduleDefinitionId, date);

  const setTemporaryNotes = useSetTemporaryNotes(scheduleDefinitionId, date);

  useEffect(() => {
    if (user) {
      setFetchData(true);
    }
  }, [user]);

  useEffect(() => {
    if (temporaryNotes && scheduleDefinitionId && date) {
      const indexOfLastProcessed = temporaryNoteLedger.findIndex(x => x.offset === temporaryNoteLedgerOffset);
      const unprocessedLedgerEntries = temporaryNoteLedger.slice(indexOfLastProcessed + 1);
      const unprocessedTasks = unprocessedLedgerEntries.map(x => x.event);
      const appts = processTemporaryNotes(scheduleDefinitionId, date, temporaryNotes, unprocessedTasks);
      setTemporaryNotes(appts);
      if (unprocessedLedgerEntries.length === 0) {
        return;
      }
      const newOffset = unprocessedLedgerEntries[unprocessedLedgerEntries.length - 1].offset;
      setTemporaryNoteLedgerOffset(newOffset);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryNotes, temporaryNoteLedger]);

  const providerValue = {
    temporaryNotes: temporaryNotes?.sort(sortByTime) ?? [],
    isLoading: temporaryNotesIsLoading,
    isError: temporaryNotesIsError,
  };

  return <TemporaryNotesContext.Provider value={providerValue}>{children}</TemporaryNotesContext.Provider>;
}

export function useTemporaryNotesContext() {
  return useContext(TemporaryNotesContext);
}
