import { Patient, PatientFilteredQuery, useGetFilteredPatients } from 'app/api/PatientApi';
import React from 'react';
import { useToast } from 'utils/toast';
import { PatientListTable } from './PatientListTable';
import { PatientFilteredQueryForm } from './PatientFilteredQueryForm';
import { usePatientListTableProvider } from './PatientListTableProvider';
import { usePatientDirectoryProvider } from './PatientDirectoryProvider';

export type PatientDirectoryProps = {
  onRowClick: (patient: Patient) => void;
  compact?: boolean;
};

export const PatientDirectory = ({ onRowClick, compact }: PatientDirectoryProps) => {
  const { setIsLoading, setPatients, setLikelyOmitted } = usePatientListTableProvider();
  const { patientFilter, setPatientFilter, resetPatientFilter } = usePatientDirectoryProvider();
  const { toastError } = useToast();

  const listPatients = useGetFilteredPatients();

  const onSubmit = async (data: PatientFilteredQuery) => {
    try {
      setIsLoading(true);
      setPatientFilter(data);
      var result = await listPatients.mutateAsync(data);
      setPatients(result.patients);
      setLikelyOmitted(result.likelyOmitted);
    } finally {
      setIsLoading(false);
    }
  };

  const onUnrecoverableError = (message: string | JSX.Element) => toastError('Error', message);

  return (
    <div className="mt-4">
      <PatientFilteredQueryForm
        defaultValues={patientFilter}
        onSubmit={onSubmit}
        onUnrecoverableError={onUnrecoverableError}
        onClear={resetPatientFilter}
        compact={compact}
      />
      <PatientListTable onRowClick={onRowClick} />
    </div>
  );
};
