import {
  SortingState,
  Table,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Patient } from 'app/api/PatientApi';
import { createContext, useContext, useMemo, useState } from 'react';
import React from 'react';

export interface IPatientListTableContext {
  table: Table<Patient>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  patientCount: number;
  setPatients: (patients: Patient[]) => void;
  likelyOmitted: boolean;
  setLikelyOmitted: (likelyOmitted: boolean) => void;
}

const PatientListTableContext = createContext<IPatientListTableContext>({
  table: {} as Table<Patient>,
  isLoading: false,
  setIsLoading: () => {},
  patientCount: 0,
  setPatients: () => {},
  likelyOmitted: false,
  setLikelyOmitted: () => {},
});

export function PatientListTableProvider({ children }: { children: React.ReactNode }) {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [likelyOmitted, setLikelyOmitted] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<Patient>();
  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.lastName, {
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        enableSorting: true,
        // enableColumnFilter: true,
        // enableMultiSort: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor(row => row.firstName, {
        id: 'firstName',
        cell: info => info.getValue(),
        header: () => <span>First Name</span>,
      }),
      columnHelper.accessor(row => row.displayLongReverse, {
        id: 'patientDisplayLongReverse',
        cell: info => info.getValue(),
        header: () => <span>Full Name</span>,
      }),
      columnHelper.accessor(row => row.birthDate, {
        id: 'birthDate',
        cell: info => info.getValue(),
        header: () => <span>Birth Date</span>,
      }),
      columnHelper.accessor(row => row.phone1, {
        id: 'phone1',
        cell: info => info.getValue(),
        header: () => <span>Phone 1</span>,
      }),
      columnHelper.accessor(row => row.phone2, {
        id: 'phone2',
        cell: info => info.getValue(),
        header: () => <span>Phone 2</span>,
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: patients ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <PatientListTableContext.Provider
      value={{
        table,
        patientCount: patients.length,
        isLoading,
        setIsLoading,
        setPatients,
        likelyOmitted,
        setLikelyOmitted,
      }}
    >
      {children}
    </PatientListTableContext.Provider>
  );
}

export const usePatientListTableProvider = () => {
  return useContext(PatientListTableContext);
};
