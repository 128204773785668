import { FetchQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type Appointment = NewAppointmentRequest &
  Auditable & {
    patientFirstName: string;
    patientLastName: string;
    patientBirthDate: string | null | undefined;
    patientPhone1: string | null | undefined;
    patientPhone2: string | null | undefined;
    patientNotes: string | null | undefined;
    appointmentNote?: string | null | undefined;
    additionalInfo?: string | null | undefined;
    // John A Smith Jr (2nd Accident)
    patientDisplayLong: string;
    // Smith, John A Jr (2nd Accident)
    patientDisplayLongReverse: string;
    // John A Smith Jr
    patientDisplayShort: string;
    // Smith, John A Jr
    patientDisplayShortReverse: string;
  };

export type NewAppointmentRequest = {
  patientId: string;
  // yyyy-MM-dd
  appointmentDate: string;
  // HH:mm
  appointmentTime: string;
  scheduleDefinitionId: string;
  appointmentTypeId: string;
  appointmentStatusId: string;
  durationMinutes: number;
};

export const useGetAppointmentsBySchedule = (
  scheduleDefinitionId: string | null | undefined,
  date: string | null | undefined,
) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointments', scheduleDefinitionId, date], async () => {
    if (!scheduleDefinitionId || !date) return [];
    let response = await client.get<Appointment[]>(`/appointment/${scheduleDefinitionId}/${date}`);
    return response.data;
  });
};

export const usePrefetchAppointments = () => {
  const queryClient = useQueryClient();
  const { computeClient: client } = useApiClient();

  return async (scheduleDefinitionId: string, date: string, staleTime: number = 0) => {
    const options: FetchQueryOptions = {
      queryKey: ['appointments', scheduleDefinitionId, date],
      queryFn: async () =>
        client.get<Appointment[]>(`/appointment/${scheduleDefinitionId}/${date}`).then(response => response.data),
      staleTime: staleTime,
    };
    await queryClient.prefetchQuery(options);
  };
};

export const useBustAppointmentCache = (id: string) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.removeQueries(['appointment', id]);
  };
};

export const useGetAppointment = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment', id], () => client.get<Appointment>(`/appointment/${id}`).then(response => response.data), {});
};

export type AppointmentDelta = {
  new: Appointment;
  old: Appointment;
};

export const useCreateAppointment = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Appointment, AxiosError, NewAppointmentRequest>({
    mutationFn: async function (request) {
      const response = await client.post('/appointment', request);
      return response.data;
    },
    onSuccess: appointment => {
      queryClient.refetchQueries(['appointments', appointment.scheduleDefinitionId, appointment.appointmentDate]);
      queryClient.refetchQueries(['patient-appointments', appointment.patientId]);
    },
  });
};

export const useSetAppointments = (scheduleDefinitionId: string | null | undefined, date: string | null | undefined) => {
  // we want to rewrite the cached query
  const queryClient = useQueryClient();
  return (appointments: Appointment[]) => {
    if (!scheduleDefinitionId || !date) {
      throw new Error('scheduleDefinitionId and date are required');
    }
    queryClient.setQueryData(['appointments', scheduleDefinitionId, date], appointments);
  };
};

export const useUpdateAppointment = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<AppointmentDelta, AxiosError, Appointment>({
    mutationFn: async function (request) {
      const response = await client.put('/appointment', request);
      return response.data;
    },
    onSuccess: appointment => {
      queryClient.refetchQueries(['appointments', appointment.new.scheduleDefinitionId, appointment.new.appointmentDate]);
      queryClient.refetchQueries(['appointments', appointment.old.scheduleDefinitionId, appointment.old.appointmentDate]);
      queryClient.refetchQueries(['patient-appointments', appointment.new.patientId]);
    },
  });
};

export const useInvalidateAppointments = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['appointments']);
    queryClient.invalidateQueries(['appointment-reminders']);
  };
};

export const useCreateAppointments = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Appointment[], AxiosError, NewAppointmentRequest[]>({
    mutationFn: async function (request) {
      const response = await client.post('/appointment/multi', request);
      return response.data;
    },
    onSuccess: appointments => {
      appointments.forEach(appointment => {
        queryClient.refetchQueries(['appointments', appointment.scheduleDefinitionId, appointment.appointmentDate]);
      });
      const patientId = appointments[0]?.patientId;
      if (patientId) {
        queryClient.refetchQueries(['patient-appointments', patientId]);
      }
    },
  });
};

export type AppointmentDeleteResponse = {
  id: string;
  scheduleDefinitionId: string;
  appointmentDate: string;
};

export const useDeleteAppointment = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<AppointmentDeleteResponse, AxiosError, string>({
    mutationFn: id => client.delete(`/appointment/${id}`).then(response => response.data),
    onSuccess: response => {
      queryClient.removeQueries(['appointment', response.id]);
      queryClient.refetchQueries(['appointments', response.scheduleDefinitionId, response.appointmentDate]);
    },
  });
};

export const useGetTimeSlotHasAvailability = (
  patientId: string,
  scheduleDefinitionId: string,
  appointmentTypeId: string,
  appointmentStatusId: string,
  appointmentDate: string,
  appointmentTime: string,
  appointmentId?: string,
) => {
  const { computeClient: client } = useApiClient();
  const request = {
    patientId,
    scheduleDefinitionId,
    appointmentTypeId,
    appointmentStatusId,
    appointmentDate,
    appointmentTime,
    appointmentId,
  };

  return useQuery(
    [
      'timeslot-has-capacity',
      scheduleDefinitionId,
      appointmentDate,
      appointmentTime,
      appointmentTypeId,
      appointmentStatusId,
      appointmentId ?? null,
    ],
    () => {
      if (
        !patientId ||
        !scheduleDefinitionId ||
        !appointmentTypeId ||
        !appointmentStatusId ||
        !appointmentDate ||
        !appointmentTime
      ) {
        return Promise.resolve(null);
      }
      return client.post<boolean>(`/appointment/timeslot-has-capacity`, request).then(response => response.data);
    },
  );
};
