import React, { createContext, useContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { getNewTokens } from 'app/components/AuthzApi';
import { MainLayout } from './layout';
import { QueryClientWrapper } from './queryClientWrapper';
import { WebsocketProvider } from './websocket';
import { ScheduleQueueProvider } from './scheduleQueueHook';
import { ProfileProvider } from './profile';
import { WebsocketWrapperProvider } from './websocket-wrapper';
import { useLocalStorage } from './localStorage';
import { TimeSlotOverflowApprovalProvider } from './timeslotOverflowApprovalHook';
import { CopyPasteProvider } from './appointmentCopyPaste';
import { PdfRedirectPage } from '../app/pages/PdfRedirectPage/Loadable';

const authHost = process.env.REACT_APP_AUTH_HOST;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const encoded_redirect_uri = encodeURIComponent(`${window.location.origin}/auth/callback/`);
const encoded_logout_uri = encodeURIComponent(`${window.location.origin}/logout`);

const loginUrl = `${authHost}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${encoded_redirect_uri}`;
const logoutUrl = `${authHost}/logout?client_id=${clientId}&logout_uri=${encoded_logout_uri}`;

export interface IAuthContext {
  user: User | null;
  logUserIn: (tokenPayload: AuthzTokenFullPayload) => void;
  logUserOut: () => void;
  setIsLoggingIn: (isLoggingIn: boolean) => void;
  refreshBearerToken: () => Promise<string>;
  bearerToken: () => string | null;
  loginUrl: string;
  logoutUrl: string;
  isLoggingIn: boolean;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  logUserIn: () => {},
  logUserOut: () => {},
  setIsLoggingIn: () => {},
  refreshBearerToken: () => Promise.resolve(''),
  bearerToken: () => null,
  loginUrl: '',
  logoutUrl: '',
  isLoggingIn: false,
});

export class User {
  constructor(
    public email: string,
    public name: string,
    public username: string,
    public sub: string,
    public groups: string[],
    public permissions: string[],
  ) {
    this.email = email;
    this.name = name;
    this.username = username;
    this.sub = sub;
    this.groups = groups;
    this.permissions = permissions;
  }
}

// This is the access token that is used as a bearer token and to get claims, user info, etc.
export interface IDeserializedIDToken {
  'cognito:username': string;
  'cognito:groups': string[];
  name: string;
  sub: string;
  email: string;
  exp: number;
  permissions: string;
}

// This is the paylod when we do a refresh token request (no refresh token is returned--it is used in the request)
export type AuthzTokenRefreshPayload = {
  id_token: string;
  access_token: string;
  expires_in: number;
  token_type: string;
};

// This is the full payload that comes from the initial login flow
export type AuthzTokenFullPayload = AuthzTokenRefreshPayload & {
  refresh_token: string;
};

const getUserFromToken = (bearerToken: string): User => {
  const decoded = jwt_decode<IDeserializedIDToken>(bearerToken);
  const email = decoded.email;
  const name = decoded.name;
  const username = decoded['cognito:username'];
  const sub = decoded.sub;
  const groups = decoded['cognito:groups'] || [];
  const permissions = decoded.permissions.split('|');
  return new User(email, name, username, sub, groups, permissions);
};

export function AuthProvider() {
  const { bearerToken, setBearerToken, refreshToken, setRefreshToken, destroyAuthTokens } = useLocalStorage();
  const [user, setUser] = useState<User | null>(() => {
    const token = bearerToken();
    if (!token) {
      return null;
    }
    return getUserFromToken(token);
  });

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const logUserIn = (tokenPayload: AuthzTokenFullPayload) => {
    setBearerToken(tokenPayload.id_token);
    setRefreshToken(tokenPayload.refresh_token);
    const user = getUserFromToken(tokenPayload.id_token);
    setUser(user);
    setIsLoggingIn(false);
  };

  const logUserOut = () => {
    destroyAuthTokens();
    setUser(null);
    return '';
  };

  const refreshBearerToken = async () => {
    if (!refreshToken) {
      return logUserOut();
    }
    try {
      const response = await getNewTokens(refreshToken);
      setBearerToken(response.id_token);
      const user = getUserFromToken(response.id_token);
      setUser(user);
      return response.id_token;
    } catch (e) {
      if (e === 'token_expired') {
        return logUserOut();
      }
      throw e;
    }
  };

  const isPdf = window.location.pathname.startsWith('/pdf');

  if (isPdf) {
    console.log('pdf');
  } else {
    console.log('not pdf');
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        logUserIn,
        logUserOut,
        refreshBearerToken,
        bearerToken,
        loginUrl,
        logoutUrl,
        isLoggingIn,
        setIsLoggingIn,
      }}
    >
      {isPdf && (
        <QueryClientWrapper>
          <PdfRedirectPage />
        </QueryClientWrapper>
      )}
      {!isPdf && (
        <>
          {!user && (
            <QueryClientWrapper>
              <MainLayout />
            </QueryClientWrapper>
          )}
          {!!user && (
            <QueryClientWrapper>
              <CopyPasteProvider>
                <ProfileProvider>
                  <WebsocketWrapperProvider>
                    <WebsocketProvider>
                      <ScheduleQueueProvider>
                        <TimeSlotOverflowApprovalProvider>
                          <MainLayout />
                        </TimeSlotOverflowApprovalProvider>
                      </ScheduleQueueProvider>
                    </WebsocketProvider>
                  </WebsocketWrapperProvider>
                </ProfileProvider>
              </CopyPasteProvider>
            </QueryClientWrapper>
          )}
        </>
      )}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
