import { createContext, useContext, useState } from 'react';
import React from 'react';
import { Patient } from '../../api/PatientApi';

export interface IPatientSessionStorageContext {
  showPastAppointments: boolean;
  setShowPastAppointments: (showPastAppointments: boolean) => void;
}

const PatientSessionStorageContext = createContext<IPatientSessionStorageContext>({
  showPastAppointments: false,
  setShowPastAppointments: () => {},
});

export function PatientSessionStorageProvider({ patient, children }: { patient: Patient; children: React.ReactNode }) {
  const [showPastAppointments, _setShowPastAppointmentsState] = useState<boolean>(
    sessionStorage.getItem(`${patient.id}-showPastAppointments`) === 'true',
  );

  const setShowPastAppointments = (showPastAppointments: boolean) => {
    _setShowPastAppointmentsState(showPastAppointments);
    sessionStorage.setItem(`${patient.id}-showPastAppointments`, showPastAppointments.toString());
  };

  return (
    <PatientSessionStorageContext.Provider
      value={{
        showPastAppointments,
        setShowPastAppointments,
      }}
    >
      <>{children}</>
    </PatientSessionStorageContext.Provider>
  );
}

export const usePatientSessionStorage = () => {
  return useContext(PatientSessionStorageContext);
};
