import * as React from 'react';
import { HomePage } from '../pages/HomePage/Loadable';
import { NotFoundPage } from './NotFoundPage/Loadable';
import { ProfilePage } from '../pages/ProfilePage/Loadable';
import { AuthCallbackPage } from '../pages/AuthCallbackPage/Loadable';
import { LogoutPage } from '../pages/LogoutPage/Loadable';
import { RequireAuth } from './RequireAuth';
import { UserManagementPage } from '../pages/UserManagementPage/Loadable';
import { Route, Routes } from 'react-router-dom';
import { PatientChartPage } from 'app/pages/PatientChartPage/Loadable';
import { ConfigurationPage } from 'app/pages/ConfigurationPage/Loadable';
import { AncillaryDataPage } from 'app/pages/AncillaryDataPage/Loadable';
import { PatientAppointmentPrintableList } from 'app/pages/PatientPage/appointmentListPrintable';
import { RequireFlexworxConfig } from 'utils/requireFlexworxConfig';
import { FlexworxConfigProvider } from 'utils/flexworx-config';
import { FlexworxAncillaryProvider } from 'utils/flexworx-ancillary';
import { ScheduleQueuesPage } from 'app/pages/ScheduleQueuesPage/Loadable';
import { KitchenSink } from 'app/pages/KitchenSinkPage';
import { TimeslotOverridePageLazy } from 'app/pages/TimeslotOverridePage/Loadable';
import { SchedulePage } from '../pages/SchedulePage/Loadable';
import { EnvironmentExplanationPage } from '../pages/EnvironmentExplanationPage';

export function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth/callback" element={<AuthCallbackPage />} />
        <Route path="/profile" element={<RequireAuth />}>
          <Route path="" element={<ProfilePage />} />
        </Route>
        <Route path="/environment-explanation" element={<RequireAuth />}>
          <Route path="" element={<EnvironmentExplanationPage />} />
        </Route>
        <Route
          path="/user-management/*"
          element={
            <RequireAuth>
              {/* todo: we need to add capability checks here */}
              <UserManagementPage />
            </RequireAuth>
          }
        />
        <Route
          path="/config/*"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                {/* todo: we need to add capability checks here */}
                <ConfigurationPage />
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/schedule-queues/*"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                {/* todo: we need to add capability checks here */}
                <ScheduleQueuesPage />
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/timeslot-overflow-requests/*"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                {/* todo: we need to add capability checks here */}
                <TimeslotOverridePageLazy />
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/ancillary/*"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                <RequireFlexworxConfig>
                  <FlexworxAncillaryProvider>
                    {/* todo: we need to add capability checks here */}
                    <AncillaryDataPage />
                  </FlexworxAncillaryProvider>
                </RequireFlexworxConfig>
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/patients/:patientId/appointments/print"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                <RequireFlexworxConfig>
                  <FlexworxAncillaryProvider>
                    <PatientAppointmentPrintableList />
                  </FlexworxAncillaryProvider>
                </RequireFlexworxConfig>
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/patients/*"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                <RequireFlexworxConfig>
                  <FlexworxAncillaryProvider>
                    {/* todo: we need to add capability checks here */}
                    <PatientChartPage />
                  </FlexworxAncillaryProvider>
                </RequireFlexworxConfig>
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/schedules/*"
          element={
            <RequireAuth>
              <FlexworxConfigProvider>
                <RequireFlexworxConfig>
                  <FlexworxAncillaryProvider>
                    {/* todo: we need to add capability checks here */}
                    <SchedulePage />
                  </FlexworxAncillaryProvider>
                </RequireFlexworxConfig>
              </FlexworxConfigProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/kitchen-sink/*"
          element={
            <RequireAuth>
              <KitchenSink />
            </RequireAuth>
          }
        />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
