import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { useAuth } from './auth';
import { PushEvent, useWebsockets } from './websocket';
import {
  TimeSlotOverflowApproval,
  useGetUnapprovedTimeSlotOverflowRequests,
  useSetTimeSlotOverflowApprovals,
} from 'app/api/TimeSlotOverflowApprovalApi';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';

const sortByCreated = (a: TimeSlotOverflowApproval, b: TimeSlotOverflowApproval) => {
  if (new Date(a.createdAt) > new Date(b.createdAt)) {
    return -1;
  }
  if (new Date(a.createdAt) < new Date(b.createdAt)) {
    return 1;
  }
  return 0;
};

export interface ITimeSlotOverflowApprovalContext {
  items: TimeSlotOverflowApproval[] | null;
  isLoading: boolean;
  isError: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<TimeSlotOverflowApproval[], unknown>>;
}

const TimeSlotOverflowApprovalContext = createContext<ITimeSlotOverflowApprovalContext>({
  items: null,
  isLoading: false,
  isError: false,
  refetch: () => {
    throw new Error('refetch function not implemented');
  },
});

const processReqeusts: (
  initialSet: TimeSlotOverflowApproval[] | null | undefined,
  newEvents: PushEvent<TimeSlotOverflowApproval>[],
) => TimeSlotOverflowApproval[] = (initialSet, newEvents) => {
  let accumulator = [...(initialSet?.sort(sortByCreated) ?? [])];

  for (const item of newEvents) {
    if (item.subject.includes('RequestCreated')) {
      const found = accumulator.find(t => t.id === item.message.id);
      if (!found) {
        accumulator.push(item.message);
      }
    }
    // we're basically treating updated just like deleted because we don't want
    // to show the approved requests in the list or in the nav bar.
    if (item.subject.includes('RequestDeleted') || item.subject.includes('RequestUpdated')) {
      const indexOfDeleted = accumulator.findIndex(t => t.id === item.message.id);
      if (indexOfDeleted > -1) {
        accumulator.splice(indexOfDeleted, 1);
      }
    }
  }

  return accumulator;
};

export function TimeSlotOverflowApprovalProvider({ children }) {
  const { user } = useAuth();

  const [fetchData, setFetchData] = useState(false);
  const {
    tsOverflowLedger: timeSlotOverflowApprovalLedger,
    tsOverflowLedgerOffset: timeSlotOverflowApprovalLedgerOffset,
    setTsOverflowLedgerOffset: setTimeSlotOverflowApprovalLedgerOffset,
  } = useWebsockets();

  const { data: overflowApprovalRequests, isLoading, isError, refetch } = useGetUnapprovedTimeSlotOverflowRequests(fetchData);

  const setTimeSlotOverflowApprovals = useSetTimeSlotOverflowApprovals();

  useEffect(() => {
    if (user) {
      setFetchData(true);
    }
  }, [user]);

  useEffect(() => {
    if (overflowApprovalRequests) {
      const indexOfLastProcessed = timeSlotOverflowApprovalLedger.findIndex(
        x => x.offset === timeSlotOverflowApprovalLedgerOffset,
      );
      const unprocessedLedgerEntries = timeSlotOverflowApprovalLedger.slice(indexOfLastProcessed + 1);
      const unprocessedItems = unprocessedLedgerEntries.map(x => x.event);
      const items = processReqeusts(overflowApprovalRequests, unprocessedItems);
      setTimeSlotOverflowApprovals(items);
      if (unprocessedLedgerEntries.length === 0) {
        return;
      }
      const newOffset = unprocessedLedgerEntries[unprocessedLedgerEntries.length - 1].offset;
      setTimeSlotOverflowApprovalLedgerOffset(newOffset);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overflowApprovalRequests, timeSlotOverflowApprovalLedger]);

  const providerValue = {
    items: overflowApprovalRequests?.sort(sortByCreated) ?? [],
    isLoading,
    isError,
    refetch,
  };

  return <TimeSlotOverflowApprovalContext.Provider value={providerValue}>{children}</TimeSlotOverflowApprovalContext.Provider>;
}

export function useTimeSlotOverflowApprovalContext() {
  return useContext(TimeSlotOverflowApprovalContext);
}
