import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type NewTimeSlotOverflowApproval = {
  patientId: string;
  scheduleDefinitionId: string;
  appointmentTime: string;
  appointmentDate: string;
};

export type TimeSlotOverflowApproval = NewTimeSlotOverflowApproval &
  Auditable & {
    isApproved: boolean;
    approvedByUser: string;
    approvedAt: string;
  };

export const useGetUnapprovedTimeSlotOverflowRequests = (enabled: boolean) => {
  const { computeClient: client } = useApiClient();
  return useQuery(
    ['timeslot-overflow-approval', 'list'],
    async () => {
      const response = await client.get<TimeSlotOverflowApproval[]>('/timeslot-overflow-approval/list');
      return response.data;
    },
    { enabled: enabled },
  );
};

export const useGetTimeSlotOverflowRequest = (
  patientId: string,
  scheduleDefinitionId: string,
  appointmentDate: string,
  appointmentTime: string,
) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['timeslot-overflow-approval', patientId, scheduleDefinitionId, appointmentDate, appointmentTime], async () => {
    const response = await client.get<TimeSlotOverflowApproval>(
      `/timeslot-overflow-approval/${patientId}/${scheduleDefinitionId}/${appointmentDate}/${appointmentTime}`,
    );
    return response.data;
  });
};

export const useCreateTimeSlotOverflowRequest = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TimeSlotOverflowApproval, AxiosError, NewTimeSlotOverflowApproval>({
    mutationFn: async function (request: NewTimeSlotOverflowApproval) {
      const response = await client.post('/timeslot-overflow-approval', request);
      return response.data;
    },
    onSuccess: data => {
      queryClient.refetchQueries([
        'timeslot-overflow-approval',
        data.patientId,
        data.scheduleDefinitionId,
        data.appointmentDate,
        data.appointmentTime,
      ]);
    },
  });
};

export const useApproveTimeSlotOverflowRequest = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, TimeSlotOverflowApproval>({
    mutationFn: async function (item: TimeSlotOverflowApproval) {
      await client.put(`/timeslot-overflow-approval/${item.id}/approve`);
    },
    onSuccess: (_, variables) => {
      queryClient.refetchQueries([
        'timeslot-overflow-approval',
        variables.patientId,
        variables.scheduleDefinitionId,
        variables.appointmentDate,
        variables.appointmentTime,
      ]);
    },
  });
};

export const useDeleteTimeSlotOverflowRequest = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, TimeSlotOverflowApproval>({
    mutationFn: async function (timeslotApproval: TimeSlotOverflowApproval) {
      await client.delete(`/timeslot-overflow-approval/${timeslotApproval.id}`);
    },
    onSuccess: (_, variables) => {
      queryClient.refetchQueries([
        'timeslot-overflow-approval',
        variables.patientId,
        variables.scheduleDefinitionId,
        variables.appointmentDate,
        variables.appointmentTime,
      ]);
    },
  });
};

export const useSetTimeSlotOverflowApprovals = () => {
  const queryClient = useQueryClient();
  return (items: TimeSlotOverflowApproval[]) => {
    queryClient.setQueryData(['timeslot-overflow-approval', 'list'], items);
  };
};

export const useInvalidateTimeSlotOverflowApproval = () => {
  const queryClient = useQueryClient();
  return (patientId: string, scheduleDefinitionId: string, appointmentDate: string, appointmentTime: string) => {
    queryClient.invalidateQueries([
      'timeslot-overflow-approval',
      patientId,
      scheduleDefinitionId,
      appointmentDate,
      appointmentTime,
    ]);
  };
};

export const useInvalidateTimeSlotOverflowApprovals = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['timeslot-overflow-approval']);
  };
};
