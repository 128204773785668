import { Patient } from 'app/api/PatientApi';
import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { LoadingAnimated } from 'app/components/LoadingAnimated';
import classNames from 'classnames';
import { usePatientListTableProvider } from './PatientListTableProvider';

export type PatientListTableProps = {
  noRecordsFoundMessage?: string | JSX.Element;
  onRowClick: (patient: Patient) => void;
};

export const PatientListTable = ({ noRecordsFoundMessage, onRowClick }: PatientListTableProps) => {
  const { table, patientCount, isLoading, likelyOmitted } = usePatientListTableProvider();

  return (
    <div>
      {isLoading && <LoadingAnimated size={32} />}
      {!isLoading && patientCount === 0 && <div className="mt-2 italic">{noRecordsFoundMessage ?? 'No records'}</div>}
      {!isLoading && patientCount > 0 && (
        <>
          {likelyOmitted && (
            <div className="text-indigo-900">
              Some patients were likely omitted from results. If you cannot find the desired patient in this search result, narrow
              your search using additional fields.
            </div>
          )}
          <div className="mt-4 flow-root">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th
                          key={header.id}
                          scope="col"
                          className={classNames(
                            'sticky top-0 z-10 border-b border-gray-300 bg-white  py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
                            header.column.getCanSort() ? 'cursor-pointer select-none' : 'cursor-default',
                          )}
                        >
                          {header.isPlaceholder ? null : (
                            <div onClick={header.column.getToggleSortingHandler()}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr key={row.id} className="hover:bg-sky-100 cursor-pointer" onClick={() => onRowClick(row.original)}>
                      {row.getVisibleCells().map(cell => (
                        <td
                          key={cell.id}
                          className={classNames(
                            index !== table.getRowModel().rows.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
