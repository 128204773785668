import { Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';
import { SimpleTableRow } from './SimpleTableRow';
import { LoadingAnimated } from '../LoadingAnimated';
import { Filter } from '../../pages/PatientPage/AppointmentListTable';

export type SimpleItemListParams<T> = {
  table: Table<T> | undefined;
  isLoading: boolean;
  isError: boolean;
  headerPlural: string;
  description?: string | JSX.Element;
  onClick: (item: T) => void;
  wrapLines?: boolean;
};

export function SimpleItemList<T>({
  table,
  isLoading,
  isError,
  headerPlural,
  description,
  onClick,
  wrapLines,
}: SimpleItemListParams<T>) {
  const totalNumber = table?.getRowModel().rows.length ?? 0;

  return (
    <>
      <div className="flex flex-col space-y-4 ">
        <h1 className="mt-4">
          <span className="text-2xl">{headerPlural}</span>
          {table && <span className="ml-2 text-lg text-gray-600 inline-block align-bottom">({totalNumber})</span>}
        </h1>
        {description && <div className="text-gray-600">{description}</div>}
      </div>

      <div className="mt-4 flex flex-col">
        {isLoading && <LoadingAnimated size={8} />}
        {isError && <div className="mt-2 italic">Error loading {headerPlural}</div>}

        <table className="min-w-full border-separate border-spacing-0">
          <thead>
            {table!.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    scope="col"
                    className={classNames(
                      'sticky top-0 z-10 border-b border-gray-300 bg-white py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
                      header.column.getCanSort() ? 'cursor-pointer select-none' : 'cursor-default',
                    )}
                  >
                    {header.isPlaceholder ? null : (
                      <div onClick={header.column.getToggleSortingHandler()}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table!} />
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table!.getRowModel().rows.map((row, index) => (
              <SimpleTableRow
                key={row.id}
                row={row}
                isLastRow={index === table!.getRowModel().rows.length - 1}
                onClick={onClick}
                wrapLines={wrapLines}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
