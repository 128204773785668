import { useQuery } from '@tanstack/react-query';
import useApiClient from 'utils/apiClientHook';
import { Patient } from './PatientApi';
import { Auditable } from './Auditable';

export type AppointmentReminder = Auditable & {
  body: string;
  appointmentDate: string;
  appointmentTime: string;
  sentAt?: string;
  patientId: string;
  toNumber: string;
  errorMsg?: string;
  firstName: string;
  lastName: string;
  appointmentIds: string[];
  reminderType: string;
  status: string;
};

export const useGetAppointmentRemindersOnTheFly = (clinicLocationId: string, date: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment-reminders', 'preview', clinicLocationId, date], async () => {
    const response = await client.get<AppointmentReminder[]>(`/appointment-reminder/preview/${clinicLocationId}/${date}`);
    return response.data;
  });
};

export const useGetAppointmentReminders = (patient: Patient) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment-reminders', 'patient', patient.id], async () => {
    const response = await client.get<AppointmentReminder[]>(`/appointment-reminder/patient/${patient.id}`);
    return response.data;
  });
};
