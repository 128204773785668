import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type NewTemporaryNoteRequest = {
  header?: string;
  body: string;
  temporaryNoteType: 'ScheduleDefinition';
  // yyyy-MM-dd
  noteDate: string;
  // HH:mm
  startTime: string;
  // HH:mm
  endTime: string;
  isAllDay: boolean;
  blockAppointments: boolean;
  targetId: string;
  color: string;
};

export type TemporaryNote = NewTemporaryNoteRequest & Auditable;

export const useGetTemporaryNote = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['temporary-note', id], () =>
    client.get<TemporaryNote>(`/temporary-note/${id}`).then(response => response.data),
  );
};

export const useGetTemporaryNotesBySchedule = (
  enabled: boolean,
  scheduleDefinitionId: string | null | undefined,
  date: string | null | undefined,
) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['temporary-note', 'schedule', scheduleDefinitionId, date], () => {
    if (!scheduleDefinitionId || !date) return Promise.resolve([]);

    return client
      .get<TemporaryNote[]>(`/temporary-note/schedule/${scheduleDefinitionId}/${date}`)
      .then(response => response.data);
  });
};

export const useCreateTemporaryNote = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TemporaryNote, AxiosError, NewTemporaryNoteRequest>({
    mutationFn: async function (request: NewTemporaryNoteRequest) {
      const response = await client.post('/temporary-note', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['temporary-note', 'schedule']);
    },
  });
};

export const useUpdateTemporaryNote = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TemporaryNote, AxiosError, TemporaryNote>({
    mutationFn: request => client.put('/temporary-note', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['temporary-note', 'schedule']);
    },
  });
};

type temporaryNoteDeletionResponse = {
  id: string;
  scheduleDefinitionId: string;
  date: string;
};

export const useDeleteTemporaryNote = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<temporaryNoteDeletionResponse, AxiosError, string>({
    mutationFn: id => client.delete(`/temporary-note/${id}`).then(response => response.data),
    onSuccess: response => {
      queryClient.removeQueries(['temporary-note', response.id]);
      queryClient.refetchQueries(['temporary-note', 'schedule', response.scheduleDefinitionId, response.date]);
      queryClient.refetchQueries(['temporary-note', response.scheduleDefinitionId, response.date]);
    },
  });
};

export const useSetTemporaryNotes = (scheduleDefinitionId: string | null | undefined, date: string | null | undefined) => {
  // we want to rewrite the cached query
  const queryClient = useQueryClient();
  return (temporaryNotes: TemporaryNote[]) => {
    if (!scheduleDefinitionId || !date) {
      throw new Error('scheduleDefinitionId and date are required');
    }
    queryClient.setQueryData(['temporary-note', 'schedule', scheduleDefinitionId, date], temporaryNotes);
  };
};

export const useInvalidateTemporaryNote = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['temporary-note']);
  };
};
