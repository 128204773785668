import { createContext, useContext, useEffect } from 'react';
import React from 'react';
import { Appointment, useGetAppointmentsBySchedule, useSetAppointments } from 'app/api/AppointmentApi';
import { PushEvent, useWebsockets } from 'utils/websocket';

export interface IAppointmentsContext {
  appointments: Appointment[] | null;
  isLoading: boolean;
  isError: boolean;
}

const AppointmentContext = createContext<IAppointmentsContext>({
  appointments: null,
  isLoading: false,
  isError: false,
});

const sortByTime = (a: Appointment, b: Appointment) => {
  // sort based on a string field called "time" formatted like HH:mm
  const aTime = a.appointmentTime.split(':');
  const bTime = b.appointmentTime.split(':');

  if (aTime[0] > bTime[0]) {
    return 1;
  }
  if (aTime[0] < bTime[0]) {
    return -1;
  }
  if (aTime[1] > bTime[1]) {
    return 1;
  }
  if (aTime[1] < bTime[1]) {
    return -1;
  }
  if (a.patientLastName > b.patientLastName) {
    return 1;
  }
  if (a.patientLastName < b.patientLastName) {
    return -1;
  }
  if (a.patientFirstName > b.patientFirstName) {
    return 1;
  }
  if (a.patientFirstName < b.patientFirstName) {
    return -1;
  }
  return 0;
};

const processAppointments: (
  scheduleDefinitionId: string | null | undefined,
  date: string | null | undefined,
  initialSet: Appointment[] | null | undefined,
  newEvents: PushEvent<Appointment>[],
) => Appointment[] = (scheduleDefinitionId, date, initialSet, newEvents) => {
  if (!scheduleDefinitionId || !date) {
    return [];
  }

  let accumulator = [...(initialSet ?? [])];

  for (const event of newEvents) {
    // Only process events for the current schedule and date
    if (event.message.scheduleDefinitionId !== scheduleDefinitionId || event.message.appointmentDate !== date) {
      continue;
    }

    if (event.subject.includes('AppointmentCreated')) {
      const found = accumulator.find(t => t.id === event.message.id);
      if (!found) {
        accumulator.push(event.message);
      }
    }

    if (event.subject.includes('AppointmentUpdated')) {
      const found = accumulator.find(t => t.id === event.message.id);
      if (found) {
        const indexOfFound = accumulator.findIndex(t => t.id === event.message.id);
        accumulator[indexOfFound] = event.message;
      }
    }

    if (event.subject.includes('AppointmentDeleted')) {
      const indexOfDeletedTask = accumulator.findIndex(t => t.id === event.message.id);
      if (indexOfDeletedTask > -1) {
        accumulator.splice(indexOfDeletedTask, 1);
      }
    }
  }

  return accumulator;
};

export function AppointmentsProvider({
  scheduleDefinitionId,
  date,
  children,
}: {
  scheduleDefinitionId: string | null | undefined;
  date: string | null | undefined;
  children: JSX.Element;
}) {
  const { appointmentLedger, setAppointmentLedgerOffset, appointmentLedgerOffset } = useWebsockets();

  const {
    data: appointments,
    isLoading: appointmentsIsLoading,
    isError: appointmentsIsError,
  } = useGetAppointmentsBySchedule(scheduleDefinitionId, date);

  const setAppointments = useSetAppointments(scheduleDefinitionId, date);

  useEffect(() => {
    if (appointments && scheduleDefinitionId && date) {
      const indexOfLastProcessed = appointmentLedger.findIndex(x => x.offset === appointmentLedgerOffset);
      const unprocessedLedgerEntries = appointmentLedger.slice(indexOfLastProcessed + 1);
      const unprocessedTasks = unprocessedLedgerEntries.map(x => x.event);
      const appts = processAppointments(scheduleDefinitionId, date, appointments, unprocessedTasks);
      setAppointments(appts);
      if (unprocessedLedgerEntries.length === 0) {
        return;
      }
      const newOffset = unprocessedLedgerEntries[unprocessedLedgerEntries.length - 1].offset;
      setAppointmentLedgerOffset(newOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentLedger]);

  const providerValue = {
    appointments: appointments?.sort(sortByTime) ?? [],
    isLoading: appointmentsIsLoading,
    isError: appointmentsIsError,
  };

  return <AppointmentContext.Provider value={providerValue}>{children}</AppointmentContext.Provider>;
}

export function useAppointmentsContext() {
  return useContext(AppointmentContext);
}
