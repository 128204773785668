import { IconDefinition, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export type ButtonLinkProps = {
  icon?: IconDefinition;
  label: string;
  to: string;
  newTab?: boolean;
};

export const ButtonLink = ({ to, label, icon, newTab }: ButtonLinkProps) => {
  const target = newTab ? '_blank' : '_self';

  return (
    <Link to={to} target={target}>
      <button
        type="button"
        className="ml-1 mr-1 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {icon && <FontAwesomeIcon icon={icon} aria-hidden="true" className="mr-2" />}
        {label}
        {newTab && <FontAwesomeIcon icon={faArrowUpRightFromSquare} aria-hidden="true" className="ml-2" />}
      </button>
    </Link>
  );
};
