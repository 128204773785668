import React, { useMemo } from 'react';
import { ListBoxGroupV2 } from '../../Forms/ListBoxGroupV2';
import { OptionFactory } from '../../Forms/Data';
import { useFlexworxConfig } from '../../../../utils/flexworx-config';

export const ScheduleDefinitionPickerClinicLocationV2 = ({
  scheduleTypeIdWatch,
  clinicLocationIdWatch,
  clinicLocationId, // Form Field
  absolutePositioning,
}: {
  scheduleTypeIdWatch: string | null;
  clinicLocationIdWatch: string | null;
  clinicLocationId: any;
  absolutePositioning?: boolean;
}) => {
  const { scheduleDefinitions, clinicLocations } = useFlexworxConfig();

  const options = useMemo(() => {
    const relevantScheduleDefinitions = scheduleTypeIdWatch
      ? scheduleDefinitions?.filter(scheduleDefinition => scheduleDefinition.scheduleTypeId === scheduleTypeIdWatch)
      : scheduleDefinitions;
    const clinicLocationIds = relevantScheduleDefinitions?.map(scheduleDefinition => scheduleDefinition.clinicLocationId) ?? [];
    return [
      OptionFactory.createOption<string>('NA', 'None Selected', null),
      ...((clinicLocations &&
        clinicLocations
          .filter(clinicLocation => clinicLocationIds.includes(clinicLocation.id))
          .sort((a, b) => (a.name || '' > (b.name || '') ? 1 : -1))
          .map(clinicLocation =>
            OptionFactory.createOption<string>(clinicLocation.id, `${clinicLocation.name}`, clinicLocation.id),
          )) ||
        []),
    ];
  }, [scheduleTypeIdWatch, scheduleDefinitions, clinicLocations]);

  return (
    <>
      <ListBoxGroupV2
        {...clinicLocationId}
        options={options}
        value={clinicLocationIdWatch}
        absolutePositioning={absolutePositioning}
      />
    </>
  );
};
