import { InitialAppointmentScheduleQueueTask, useInvalidateInitialApptTasks } from 'app/api/ScheduleQueueApi';
import { createContext, useContext, useEffect } from 'react';
import React from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Appointment, useInvalidateAppointments } from 'app/api/AppointmentApi';
import { TimeSlotOverflowApproval, useInvalidateTimeSlotOverflowApprovals } from 'app/api/TimeSlotOverflowApprovalApi';
import { useWebsocketWrapper } from './websocket-wrapper';
import { TemporaryNote, useInvalidateTemporaryNote } from 'app/api/TemporaryNoteApi';
import { useWebsocketLedger } from './websocket-ledger';

export interface IWebsocketContext {
  isConnecting: boolean;
  isOpen: boolean;
  isClosing: boolean;
  isClosed: boolean;
  isUninstantiated: boolean;
  connectionStatus: string;

  initialApptTaskLedger: LedgerEntry<InitialAppointmentScheduleQueueTask>[];
  setInitialApptTaskLedgerOffset: (offset: number) => void;
  initialApptTaskLedgerOffset: number;

  appointmentLedger: LedgerEntry<Appointment>[];
  setAppointmentLedgerOffset: (offset: number) => void;
  appointmentLedgerOffset: number;

  temporaryNoteLedger: LedgerEntry<TemporaryNote>[];
  setTemporaryNoteLedgerOffset: (offset: number) => void;
  temporaryNoteLedgerOffset: number;

  tsOverflowLedger: LedgerEntry<TimeSlotOverflowApproval>[];
  setTsOverflowLedgerOffset: (offset: number) => void;
  tsOverflowLedgerOffset: number;
}

const WebsocketContext = createContext<IWebsocketContext>({
  isConnecting: false,
  isOpen: false,
  isClosing: false,
  isClosed: false,
  isUninstantiated: true,
  connectionStatus: 'Uninstantiated',

  initialApptTaskLedger: [],
  setInitialApptTaskLedgerOffset: number => {},
  initialApptTaskLedgerOffset: 0,

  appointmentLedger: [],
  setAppointmentLedgerOffset: number => {},
  appointmentLedgerOffset: 0,

  tsOverflowLedger: [],
  setTsOverflowLedgerOffset: number => {},
  tsOverflowLedgerOffset: 0,

  temporaryNoteLedger: [],
  setTemporaryNoteLedgerOffset: number => {},
  temporaryNoteLedgerOffset: 0,
});

export type PushEvent<T> = {
  topic: string;
  subject: string;
  timestamp: string;
  message: T;
};

export type LedgerEntry<T> = {
  offset: number;
  event: PushEvent<T>;
};

export function WebsocketProvider({ children }) {
  const { websocketUrl, setError } = useWebsocketWrapper();
  const {
    offset: appointmentLedgerOffset,
    addToLedger: addToAppointmentLedger,
    ledger: appointmentLedger,
    trySetOffset: setAppointmentLedgerOffset,
  } = useWebsocketLedger<Appointment>();

  const {
    offset: initialApptTaskLedgerOffset,
    addToLedger: addToApptTaskLedger,
    ledger: initialApptTaskLedger,
    trySetOffset: setInitialApptTaskLedgerOffset,
  } = useWebsocketLedger<InitialAppointmentScheduleQueueTask>();

  const {
    offset: tsOverflowLedgerOffset,
    addToLedger: addToTsOverflowLedger,
    ledger: tsOverflowLedger,
    trySetOffset: setTsOverflowLedgerOffset,
  } = useWebsocketLedger<TimeSlotOverflowApproval>();

  const {
    offset: temporaryNoteLedgerOffset,
    addToLedger: addToTemporaryNoteLedger,
    ledger: temporaryNoteLedger,
    trySetOffset: setTemporaryNoteLedgerOffset,
  } = useWebsocketLedger<TemporaryNote>();

  const invalidateAppointments = useInvalidateAppointments();
  const invalidateInitialApptTasks = useInvalidateInitialApptTasks();
  const invalidateTimeSlotOverflowApprovals = useInvalidateTimeSlotOverflowApprovals();
  const invalidateTemporaryNotes = useInvalidateTemporaryNote();

  const { lastMessage, readyState } = useWebSocket(websocketUrl, {
    share: true,
    shouldReconnect: e => true,
    reconnectAttempts: 5,
    reconnectInterval: 8000,
    onError: e => {
      console.log('error at websocket connection, reconnecting...', websocketUrl);
      setError(e);
    },
  });

  useEffect(() => {
    if (isOpen) {
      console.log('websocket connection established', websocketUrl);
      // We need to invalidate the current state of objects that we update with websockets
      // because there could have been events we missed.
      invalidateAppointments();
      invalidateInitialApptTasks();
      invalidateTimeSlotOverflowApprovals();
      invalidateTemporaryNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useEffect(() => {
    if (lastMessage !== null) {
      const record = JSON.parse(lastMessage.data);

      if (record.topic === 'appointment-queue-events') {
        addToApptTaskLedger(record);
        return;
      } else if (record.topic === 'appointment-events') {
        addToAppointmentLedger(record);
        return;
      } else if (record.topic === 'temporary-note-events') {
        addToTemporaryNoteLedger(record);
        return;
      } else if (record.topic === 'time-slot-overflow-request-events') {
        addToTsOverflowLedger(record);
        return;
      }

      console.log('unknown topic', record.topic);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const isConnecting = readyState === ReadyState.CONNECTING;
  const isOpen = readyState === ReadyState.OPEN;
  const isClosing = readyState === ReadyState.CLOSING;
  const isClosed = readyState === ReadyState.CLOSED;
  const isUninstantiated = readyState === ReadyState.UNINSTANTIATED;

  return (
    <WebsocketContext.Provider
      value={{
        isConnecting,
        isOpen,
        isClosing,
        isClosed,
        isUninstantiated,
        connectionStatus,

        initialApptTaskLedger,
        setInitialApptTaskLedgerOffset,
        initialApptTaskLedgerOffset,

        appointmentLedger,
        setAppointmentLedgerOffset,
        appointmentLedgerOffset,

        tsOverflowLedger,
        setTsOverflowLedgerOffset,
        tsOverflowLedgerOffset,

        temporaryNoteLedger,
        setTemporaryNoteLedgerOffset,
        temporaryNoteLedgerOffset,
      }}
    >
      <>{children}</>
    </WebsocketContext.Provider>
  );
}

export const useWebsockets = () => {
  return useContext(WebsocketContext);
};
