import { useState } from 'react';
import { LedgerEntry, PushEvent } from './websocket';

export function useWebsocketLedger<T>() {
  // The appointment ledger is the ledger of all the events.
  const [ledger, setLedger] = useState<LedgerEntry<T>[]>([]);
  // The offset is like a consumer offset. There's another process dealing with processing events, but centrally stored offset.
  const [offset, setOffset] = useState<number>(-1);
  const trySetOffset = (_offset: number) => {
    if (_offset > offset) {
      setOffset(offset);
    }
  };
  const addToLedger = (event: PushEvent<T>) =>
    setLedger(prev => {
      const o = prev?.length ? prev[prev.length - 1].offset + 1 : 0;
      return prev.concat({ offset: o, event });
    });

  return {
    ledger,
    offset,
    trySetOffset,
    addToLedger,
  };
}
