import { forwardRef, Fragment, useEffect, useId, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Option } from './Data';
import { GenericInputGroupProps } from './InputGroup';
import { InfoTip } from '../Tooltip';

export type ListBoxGroupRenderProps = GenericInputGroupProps<HTMLSelectElement, string> & {
  options: Option<string>[];
  onChange?: (value: Option<string>) => void;
  initialValue?: string;
  showCheckIcon: boolean;
  absolutePositioning?: boolean;
};

export type ListBoxGroupProps = ListBoxGroupRenderProps & {
  formKey: string | number;
};

// this is a hack sometimes necessary for re-render.
// the main issue is the listbox components.
// I've wasted too much time trying to figure out why they don't re-render properly.
// 2023-04-25
export const ListBoxGroup = forwardRef<HTMLSelectElement, ListBoxGroupProps>(
  (props: ListBoxGroupProps, ref: React.Ref<HTMLSelectElement>) => {
    let key = props.formKey || 'no-key';
    return <ListBoxGroupRender {...props} ref={ref} key={key} />;
  },
);

const ListBoxGroupRender = forwardRef<HTMLSelectElement, ListBoxGroupProps>(
  (
    {
      options,
      label,
      clearErrors,
      setValue: _setValue,

      errors,
      initialValue,
      showCheckIcon,
      absolutePositioning,
      tooltip,
    }: ListBoxGroupProps,
    ref: React.Ref<HTMLSelectElement>,
  ) => {
    const [value, __setValue] = useState<string | null>(initialValue || null);

    const setValue = (value: string) => {
      __setValue(value);
      _setValue(value);
    };

    useEffect(() => {
      if (initialValue) {
        setValue(initialValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selected, setSelected] = useState<Option<string> | undefined>(
      options.find(o => o.value === value) || options.find(o => o.value === null),
    );

    const onChange: (selected: Option<string>) => void = selected => {
      clearErrors();
      setValue(selected.value as string);
      setSelected(selected);
    };

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    const getClass = () => {
      const baseClass =
        'relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm';

      const classes = [baseClass];

      if (errors) {
        classes.push('border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500');
      }

      if (value === null || value === undefined) {
        classes.push('text-gray-400');
      }

      return classNames(...classes);
    };

    return (
      <>
        <Listbox value={selected} onChange={onChange}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                {label}
                {tooltip && <InfoTip content={tooltip} />}
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className={getClass()}>
                  <span className="block truncate">{selected?.description}</span>
                  {errors && (
                    <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center pr-3">
                      <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
                    </div>
                  )}

                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={classNames(
                      'mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                      absolutePositioning ? 'absolute z-10' : '',
                    )}
                  >
                    {options.map(option => (
                      <Listbox.Option
                        key={option.key}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {option.description}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                )}
                              >
                                {showCheckIcon && <CheckIcon className="h-5 w-5" aria-hidden="true" />}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </>
    );
  },
);
