import React from 'react';
import { InputGroup } from '../Forms/InputGroup';
import { PhoneInputGroup } from '../Forms/PhoneInputGroup';
import { TextAreaGroup } from '../Forms/TextAreaGroup';
import useField from '../Forms/FieldHook';
import { phoneNumberRegex } from '../Forms/PhoneMaskHook';
import { RadioBooleanInputGroup } from '../Forms/RadioBooleanInputGroup';
import { AgeInputGroup } from '../Forms/AgeInputGroup';
import { ListBoxGroupV2 } from '../Forms/ListBoxGroupV2';
import { OptionFactory } from '../Forms/Data';
import { SectionDivider } from './SectionDivider';

export type SectionDemographicsProps = {
  fieldArguments: any;
  watch: any;
  setValue: any;
};

export const SectionDemographics = ({ fieldArguments, watch, setValue }: SectionDemographicsProps) => {
  const firstName = useField(fieldArguments('firstName', 'First Name'));
  const middleInitial = useField(fieldArguments('middleInitial', 'MI'));
  const lastName = useField(fieldArguments('lastName', 'Last Name'));
  const descriptor = useField(fieldArguments('descriptor', 'Descriptor'));
  const suffix = useField(fieldArguments('suffix', 'Suffix'));

  const birthDate = useField(
    fieldArguments('birthDate', 'Birth Date', {
      value: new RegExp('^(19|20)?[0-9]{2}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$'),
      message: 'Invalid Date',
    }),
  );

  const phone1 = useField(
    fieldArguments('phone1', 'Primary Phone', {
      value: phoneNumberRegex,
      message: 'Invalid Phone Number',
    }),
  );
  const phone1CanText = useField(fieldArguments('phone1CanText', 'Allow Text'));
  const isMinor = useField(fieldArguments('isMinor', 'Is Minor?'));
  const isPregnant = useField(fieldArguments('isPregnant', 'Chance of Pregnancy?'));
  const isParentInvolved = useField(fieldArguments('isParentInvolved', 'Parent Involved?'));
  const phone2 = useField(
    fieldArguments('phone2', 'Emergency Phone', {
      value: phoneNumberRegex,
      message: 'Invalid Phone Number',
    }),
  );

  const phone2Description = useField(fieldArguments('phone2Description', 'Emergency Contact'));

  const notes = useField(fieldArguments('notes', 'Patient Notes'));

  const watchPhone1CanText: boolean = watch('phone1CanText');
  const watchBirthDate: string = watch('birthDate');
  const watchIsMinor: boolean = watch('isMinor');
  const watchSex: string = watch('sex');
  const watchParentInvolved = watch('isParentInvolved');
  const watchIsPregnant = watch('isPregnant');
  const setSex = (value: string) => {
    setValue('sex', value);
  };

  const bulletOptions = [
    { description: 'Yes, will receive texts', value: true },
    { description: "No, won't receive texts", value: false },
  ];

  const sexOptions = [
    OptionFactory.createOption('not-specified', 'Not Specified', 'not-specified'),
    OptionFactory.createOption('male', 'Male', 'male'),
    OptionFactory.createOption('female', 'Female', 'female'),
    OptionFactory.createOption('other', 'Other', 'other'),
  ];

  const zip = useField(fieldArguments('zip', 'Patient Zip Code'));

  const isMinorOptions = [
    { description: 'Yes', value: true },
    { description: 'No', value: false },
  ];

  const chanceOfPregnancy = [
    { description: 'Yes', value: true },
    { description: 'No', value: false },
  ];

  const parentInvolvedOptions = [
    { description: 'Yes', value: true },
    { description: 'No', value: false },
    { description: 'N/A', value: null },
  ];

  const phone1CanTextTooltip = 'Has this patient given permission to receive text messages?';

  return (
    <div className="gap-y-2 gap-x-2">
      <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-16">
        <div className="sm:col-span-3">
          <InputGroup {...firstName} type="text" placeholder="Bob" />
        </div>
        <div className="sm:col-span-1">
          <InputGroup {...middleInitial} type="text" placeholder="S" />
        </div>
        <div className="sm:col-span-3">
          <InputGroup {...lastName} type="text" placeholder="Cranston" />
        </div>
        <div className="sm:col-span-1">
          <InputGroup {...suffix} type="text" placeholder="Jr" />
        </div>
        <div className="sm:col-span-3">
          <InputGroup
            {...descriptor}
            type="text"
            placeholder="Second Accident"
            tooltip={'Something that describes this patient uniquely, like "Second Accident"'}
          />
        </div>
        <div className="sm:col-span-2">
          <ListBoxGroupV2
            label="Sex"
            name="sex"
            clearErrors={() => {}}
            setValue={setSex}
            options={sexOptions}
            value={watchSex}
            absolutePositioning={true}
          />
        </div>
        <div className="sm:col-span-3">
          <AgeInputGroup {...birthDate} value={watchBirthDate} />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-16">
        <div className={'sm:col-span-3'}>
          <InputGroup {...zip} type="text" placeholder="38112" />
        </div>
        <div className="sm:col-span-1 border border-r-1 border-t-0 border-b-0 border-l-0 border-indigo-700"></div>
        <div className="sm:col-span-3 border border-r-1 border-t-0 border-b-0 border-l-0 border-indigo-700">
          <RadioBooleanInputGroup
            {...isMinor}
            options={isMinorOptions}
            inlineList={true}
            value={watchIsMinor}
            tooltip="Is the patient younger than 18?"
          />
        </div>
        <div className="sm:col-span-4  border border-r-1 border-t-0 border-b-0 border-l-0 border-indigo-700">
          <RadioBooleanInputGroup
            {...isParentInvolved}
            options={parentInvolvedOptions}
            inlineList={true}
            value={watchParentInvolved}
            tooltip="Was the parent or guardian involved in the Injury?"
          />
        </div>

        <div className="sm:col-span-4">
          <RadioBooleanInputGroup
            {...isPregnant}
            options={chanceOfPregnancy}
            inlineList={true}
            value={watchIsPregnant}
            tooltip="Is there any chance that the patient is pregnancy?"
          />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
        <div className="sm:col-span-4">
          <div className="my-2 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
            <div className="sm:col-span-4">
              <PhoneInputGroup {...phone1} placeholder="(555) 555-5555" />
            </div>
            <div className="sm:col-span-4">
              <RadioBooleanInputGroup
                {...phone1CanText}
                options={bulletOptions}
                inlineList={false}
                value={watchPhone1CanText}
                tooltip={phone1CanTextTooltip}
              />
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
            <div className="sm:col-span-8">
              <SectionDivider />
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
            <div className="sm:col-span-4">
              <PhoneInputGroup {...phone2} placeholder="(555) 555-5555" />
            </div>
            <div className="sm:col-span-4">
              <InputGroup
                {...phone2Description}
                type="text"
                placeholder="Husband, Thomas S."
                tooltip="Name and relation to patient"
              />
            </div>
          </div>
        </div>
        <div className="sm:col-span-5">
          <TextAreaGroup {...notes} placeholder="Something about the patient..." rows={5} />
        </div>
      </div>
    </div>
  );
};
