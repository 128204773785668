import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';

export type InitialAppointmentScheduleQueueTask = {
  id: string;
  patientId: string;
  createdAt: string;
  organizationId: string;
  priorityTimestamp: string;
  patientFirstName: string;
  patientLastName: string;
  patientBirthDate: string;
  patientPhone1: string;
  patientPhone2: string;
  patientNotes: string;
  patientDisplayLongReverse: string;
  patientDisplayLong: string;
  patientDisplayShortReverse: string;
  patientDisplayShort: string;
};

export const useGetInitialAppointmentQueueTasks = (enabled: boolean) => {
  const { computeClient: client } = useApiClient();
  return useQuery(
    ['schedule-queue/initial-appointments'],
    () =>
      client
        .get<InitialAppointmentScheduleQueueTask[]>(`/schedule-queue/initial-appointments`, {})
        .then(response => response.data),
    { enabled: enabled },
  );
};

export const useDeprioritizeInitialAppointmentQueueTask = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string>({
    mutationFn: async function (taskId: string) {
      await client.post(`/schedule-queue/initial-appointments/${taskId}/deprioritize`);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-queue/initial-appointments']);
    },
  });
};

export const useReprioritizeInitialAppointmentQueueTask = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string>({
    mutationFn: async function (taskId: string) {
      await client.post(`/schedule-queue/initial-appointments/${taskId}/reprioritize`);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-queue/initial-appointments']);
    },
  });
};

export const useSetInitialAppointmentQueueTasks = () => {
  // we want to rewrite the cached query 'schedule-queue/initial-appointments'
  const queryClient = useQueryClient();
  return (tasks: InitialAppointmentScheduleQueueTask[]) => {
    queryClient.setQueryData(['schedule-queue/initial-appointments'], tasks);
  };
};

export const useDeleteInitialAppointmentQueueTask = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string>({
    mutationFn: async function (taskId: string) {
      await client.delete(`/schedule-queue/initial-appointments/${taskId}`);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-queue/initial-appointments']);
    },
  });
};

export const useInvalidateInitialApptTasks = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['schedule-queue/initial-appointments']);
  };
};
