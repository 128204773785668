import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import {
  InitialAppointmentScheduleQueueTask,
  useGetInitialAppointmentQueueTasks,
  useSetInitialAppointmentQueueTasks,
} from 'app/api/ScheduleQueueApi';
import { useAuth } from './auth';
import { PushEvent, useWebsockets } from './websocket';

const sortByPriority = (a: InitialAppointmentScheduleQueueTask, b: InitialAppointmentScheduleQueueTask) => {
  if (new Date(a.priorityTimestamp) > new Date(b.priorityTimestamp)) {
    return -1;
  }

  if (new Date(a.priorityTimestamp) < new Date(b.priorityTimestamp)) {
    return 1;
  }

  return 0;
};

export interface IScheduleQueueContext {
  initialAppointmentTasks: InitialAppointmentScheduleQueueTask[] | null;
  isLoading: boolean;
  isError: boolean;
}

const ScheduleQueueContext = createContext<IScheduleQueueContext>({
  initialAppointmentTasks: null,
  isLoading: false,
  isError: false,
});

const processInitialAppointmentScheduleQueueTasks: (
  initialSet: InitialAppointmentScheduleQueueTask[] | null | undefined,
  newEvents: PushEvent<InitialAppointmentScheduleQueueTask>[],
) => InitialAppointmentScheduleQueueTask[] = (initialSet, newEvents) => {
  let accumulator = [...(initialSet?.sort(sortByPriority) ?? [])];

  for (const task of newEvents) {
    if (task.subject.includes('TaskCreated')) {
      const found = accumulator.find(t => t.id === task.message.id);
      if (!found) {
        accumulator.push(task.message);
      }
    }
    if (task.subject.includes('TaskDeleted')) {
      const indexOfDeletedTask = accumulator.findIndex(t => t.id === task.message.id);
      if (indexOfDeletedTask > -1) {
        accumulator.splice(indexOfDeletedTask, 1);
      }
    }
    if (task.subject.includes('TaskUpdated')) {
      const indexOfUpdatedTask = accumulator.findIndex(t => t.id === task.message.id);
      if (indexOfUpdatedTask > -1) {
        accumulator[indexOfUpdatedTask] = task.message;
      }
    }
  }

  return accumulator;
};

export function ScheduleQueueProvider({ children }) {
  const { user } = useAuth();

  const [fetchData, setFetchData] = useState(false);
  const { initialApptTaskLedger, initialApptTaskLedgerOffset, setInitialApptTaskLedgerOffset } = useWebsockets();

  const {
    data: initialAppointmentQueueTasks,
    isLoading: initialAppointmentTasksIsLoading,
    isError: initialAppointmentTasksIsError,
  } = useGetInitialAppointmentQueueTasks(fetchData);

  const setInitialAppointmentQueueTasks = useSetInitialAppointmentQueueTasks();

  useEffect(() => {
    if (user) {
      setFetchData(true);
    }
  }, [user]);

  useEffect(() => {
    if (initialAppointmentQueueTasks) {
      const indexOfLastProcessed = initialApptTaskLedger.findIndex(x => x.offset === initialApptTaskLedgerOffset);
      const unprocessedLedgerEntries = initialApptTaskLedger.slice(indexOfLastProcessed + 1);
      const unprocessedTasks = unprocessedLedgerEntries.map(x => x.event);
      const tasks = processInitialAppointmentScheduleQueueTasks(initialAppointmentQueueTasks, unprocessedTasks);
      setInitialAppointmentQueueTasks(tasks);
      if (unprocessedLedgerEntries.length === 0) {
        return;
      }
      const newOffset = unprocessedLedgerEntries[unprocessedLedgerEntries.length - 1].offset;
      setInitialApptTaskLedgerOffset(newOffset);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAppointmentQueueTasks, initialApptTaskLedger]);

  const providerValue = {
    initialAppointmentTasks: initialAppointmentQueueTasks?.sort(sortByPriority) ?? [],
    isLoading: initialAppointmentTasksIsLoading,
    isError: initialAppointmentTasksIsError,
  };

  return <ScheduleQueueContext.Provider value={providerValue}>{children}</ScheduleQueueContext.Provider>;
}

export function useScheduleQueueContext() {
  return useContext(ScheduleQueueContext);
}
